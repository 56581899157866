import React, { useContext } from 'react';
import { PlayerContext } from '../contexts/PlayerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/PersistentPlayer.css';

function PersistentPlayer() {
  const {
    currentTrack,
    isPaused,
    playerRef,
  } = useContext(PlayerContext);

  return (
    <div className="persistent-player bg-dark text-white p-2 d-flex justify-content-between align-items-center">
      <div className="track-info">
        {currentTrack ? (
          <>
            <strong>{currentTrack.name}</strong> -{' '}
            {currentTrack.artists?.[0]?.name || 'Unknown Artist'}
          </>
        ) : (
          'No track playing'
        )}
      </div>
      <div className="player-controls">
        {currentTrack ? (
          <>

            <button
              className={`btn btn-lg ${isPaused ? 'btn-success' : 'btn-danger'} mx-2`}
              onClick={() => playerRef.current?.togglePlay()}
            >
              {isPaused ? 'Play' : 'Pause'}
              <FontAwesomeIcon icon={isPaused ? faPlay : faPause} className="ms-2" />


            </button>

          </>
        ) : (
          <span>Player inactive</span>
        )}
      </div>
    </div>
  );
}

export default PersistentPlayer;
