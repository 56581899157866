import React from 'react';

export default function DismissAlert({ type, message, onClose }) {
  return (
    <div
      className={`my-2 alert alert-${type} alert-dismissible fade show`}
      role="alert"
    >
      <strong>{message}</strong>
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={onClose}
      ></button>
    </div>
  );
}