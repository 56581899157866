import { useState, useEffect } from 'react';

const useSiteAccess = () => {
  const [siteAccessGranted, setSiteAccessGranted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSiteAccess = async () => {
      try {
        const response = await fetch('/api/site-access');
        console.log('Raw response from /api/site-access:', response);

        if (!response.ok || response.headers.get('Content-Type') !== 'application/json') {
          throw new Error('Invalid response or content type');
        }

        const data = await response.json();
        console.log('Parsed JSON from /api/site-access:', data);

        setSiteAccessGranted(data.siteAccessGranted);
      } catch (error) {
        console.error('Error checking site access:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSiteAccess();
  }, []);

  return { siteAccessGranted, loading };
};

export default useSiteAccess;
